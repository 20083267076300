@import-normalize;

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f9;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}
